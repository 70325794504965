import React from 'react';
import './styles.css';

function Timeline() {
    return (
        <ul className="timeline timeline-centered">
            <li className="timeline-item">
                <div className="timeline-info">
                    Jun to Dec 2017
                </div>
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                    <div className="timeline-title">
                        <span>IT Co-Op</span>
                        <div className="dot" />
                        <span className="secondary">Johnson &amp; Johnson</span>
                    </div>
                    <ul>
                        <li>Implemented several user performance and feedback features for the re-designed 7 Minute Workout application.</li>
                        <li>Established initial functionality for several key features of Health Partner for Knees and Hips, a medical coaching application for joint replacement surgery. Examined an established iOS companion app using a sparsely documented API to obtain application content.</li>
                        <li>Orchestrated the creation and replacement of 86 new audio files upgrading diagnostic pen and paper clinical assessment to iOS application for patients with depression.</li>
                    </ul>
                </div>
            </li>
            <li className="timeline-item">
                <div className="timeline-info">
                    May to Aug 2018
                </div>
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                <div className="timeline-title">
                        <span>IT Intern</span>
                        <div className="dot" />
                        <span className="secondary">Johnson &amp; Johnson</span>
                    </div>
                    <ul>
                        <li>Extracted similar functionality from two separate internal applications into shared widgets for code reduction and quality while building a monorepo project.</li>
                        <li>Developed a proof-of-concept mobile application that integrates an externally created biosensor for disease diagnosis and monitoring. Device was scheduled for healthy volunteer study Q3 2018 for data collection and analysis.</li>
                        <li>Coordinated all official communications while co-planning the 2018 company-wide Women&apos;s Leadership and Inclusion Conference for 50 total speakers and participants.</li>
                    </ul>
                </div>
            </li>
            <li className="timeline-item">
                <div className="timeline-info">
                    Jan to July 2019
                </div>
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                <div className="timeline-title">
                        <span>Software Engineer Co-Op</span>
                        <div className="dot" />
                        <span className="secondary">Intuit</span>
                    </div>
                    <ul>
                        <li>Developed end-to-end anonymous email feature that integrated with Amazon&apos;s Simple Email Service and existing backend service to gather accountant reply rate data to drive future feature development.</li>
                        <li>Assisted the development of a feature aimed to increase the average number of leads sent to accountants by individual visitors. Final feature raised the average from 1.8 leads sent per visitor to 2.2.</li>
                        <li>Spearheaded development of initial version of an in-house messaging system for accountants and their leads, with potential to incorporate into other locations of the product.</li>
                    </ul>
                </div>
            </li>
            <li className="timeline-item">
                <div className="timeline-info">
                    May 8th, 2020
                </div>
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                    <h3 class="timeline-title">BS / MS in Computer Science</h3>
                    <p>Rochester Institute of Technology</p>
                </div>
            </li>
            <li className="timeline-item">
                <div className="timeline-info">
                    Jun 2020 to Present
                </div>
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                <div className="timeline-title">
                        <span>Software Engineer</span>
                        <div className="dot" />
                        <span className="secondary">Intuit</span>
                    </div>
                    <ul>
                        <li>Quickly assimilated into the team and become primary front-end resource for our customer experience.</li>
                        <li>Composed initial design for improving our customer debugging experience to reduce strain on oncall rotation.</li>
                    </ul>
                </div>
            </li>
        </ul>
    );
};

export default Timeline;