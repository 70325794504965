import React from 'react';
import { Card, Icon, Image } from 'semantic-ui-react';

function ProjectCard(props) {
    return (
        <Card style={{ margin: '0 32px 0 0' }}>
            <Image src={props.imageSrc} wrapped ui={false} />
            <Card.Content>
                <Card.Header>{props.title}</Card.Header>
                <Card.Description>
                    {props.description}
                </Card.Description>
                </Card.Content>
                <Card.Content extra>
                <a href={props.link} target="_blank">
                    <Icon name='github' />
                    Github
                </a>
            </Card.Content>
        </Card>
    );
};

export default ProjectCard;