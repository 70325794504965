import React from 'react';

import { Image, Icon } from 'semantic-ui-react';

import homephoto from '../../assets/profile-photo.png';
import background from '../../assets/profile-background.jpg';

import './styles.css';

function Home() {
    return (
        <div
            id="homeRoot"
            className='home-container'
            style={{ backgroundImage: `url('${background}')`}}
        >
            <div className="image-container">
                <Image src={homephoto} size="small" />
                <div>
                    <span className="styled-name">theodora&nbsp;</span>
                    <span className="styled-name" style={{ color: '#FF8484' }}>bendlin</span>
                </div>
                <Icon className="continue-icon" name='angle double down' size="large" circular />
            </div>
        </div>
    );
}

export default Home;