import React from 'react';
import styled from "styled-components";

const SkillBarContainer = styled.div`
    width: 350px;
    display: flex;
    flex-direction: row;
    margin: 15px 0;
`;

const SkillLabel = styled.span`
    margin-bottom: 10px;
    margin: auto 10px;
    width: 100px;
    font-weight: 500;
    text-align: left;
`;

const BaseBar = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    background-color: #F8F8F8;
`;

const ProgressBar = styled.div`
    height: 24px;
    width: ${props => props.progress}%;
    background-color: #9ABD97;
`;

const ProgressLabel = styled.span`
    margin: auto 8px auto 0;
    font-size: 12px;
`;

function SkillBar(props) {
    return (
        <SkillBarContainer>
            <SkillLabel>{props.skillname}</SkillLabel>
            <BaseBar>
                <ProgressBar progress={props.progress} />
                <ProgressLabel>{props.progress}%</ProgressLabel>
            </BaseBar>
        </SkillBarContainer>
    );
};

export default SkillBar;

