import React from "react";

import { Icon } from 'semantic-ui-react';

import './styles.css'

function Footer() {
    return (
        <div className="footer-container">
            <div className="icons-container">
                <a href=""><Icon name="facebook" size="large" circular /></a>
                <a href="https://github.com/tbendlin" target="_blank"><Icon name="github" size="large" circular /></a>
                <a href="www.linkedin.com/in/tbendlin"><Icon name="linkedin" size="large" circular /></a>
            </div>
        </div>
    );
};

export default Footer;