import React, { useState, createRef } from 'react';
import './styles.css';

import { Sticky } from 'semantic-ui-react';


function NavigationBar() {
    const [selectedSection, setSelectedSection] = useState('home');
    const contextRef = createRef();

    const getClassNameForSelected = (sectionName) => {
        return selectedSection === sectionName ? 'active' : ''
    };

    return (
        <div ref={contextRef}>
            <Sticky context={contextRef} pushing>
                <ul className="navigation-bar">
                    <li className="navigation-item">
                        <a
                            className={getClassNameForSelected('contact')}
                            href="#contactRoot"
                            onClick={() => setSelectedSection('contact')}
                        >
                            Contact
                        </a>
                    </li>
                    <li className="navigation-item">
                        <a
                            className={getClassNameForSelected('projects')}
                            href="#projectsRoot"
                            onClick={() => setSelectedSection('projects')}
                        >
                            Projects
                        </a>
                    </li>
                    <li className="navigation-item">
                        <a
                            className={getClassNameForSelected('about')}
                            href="#aboutRoot"
                            onClick={() => setSelectedSection('about')}
                        >
                            About
                        </a>
                    </li>
                    <li className="navigation-item">
                        <a
                            className={getClassNameForSelected('home')}
                            href="#homeRoot"
                            onClick={() => setSelectedSection('home')}
                        >
                            Home
                        </a>
                    </li>
                </ul>
            </Sticky>
        </div>
    );
}

export default NavigationBar;