import React from 'react';
import './styles.css';

import ProjectCard from './ProjectCard';

function Projects() {
    return (
        <div id="projectsRoot" className="page-container projects-container">
            <div className="primary-text">Projects</div>
            <div className="secondary-text" style={{ marginBottom: '80px' }}>What I&apos;ve Been Working On</div>
            <div className='projects-flipbook'>
                <ProjectCard
                    imageSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEOBr2DguW6kCCCtgvL20MeX2yw4Z5YVIxmQ&usqp=CAU"
                    title="Early Detection of Depression via Social Media Posts"
                    description="My final capstone project, which aimed to use a martingale framework to help detect early indicators of suicide from Reddit posts."
                    link="https://github.com/tbendlin/AudioEmotionCNN"
                />
                <ProjectCard
                    imageSrc="https://static.onecms.io/wp-content/uploads/sites/24/2019/06/gettyimages-830089276.jpg"
                    title="FrankenFurby"
                    description="A fun summer research project where I transformed a Furby tool into a smart device that can perform emotion detection on a voice sample and play music if it thinks a person is upset."
                    link="https://github.com/tbendlin/AudioEmotionCNN"
                />
                <ProjectCard
                    imageSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF3jmzQ80nm39vbX13Mmaydxiq46wMXZJzYw&usqp=CAU"
                    title="sMYle"
                    description="A passion project I thought of my freshman year of college. Its aim was to create an app that encourages users to submit compliments that will randomly send to themselves later to help build their self-confidence."
                    link="https://github.com/tbendlin/sMYleAndroid/tree/develop"
                />
            </div>
        </div>
    );
}

export default Projects;