import React from 'react';

import { Form, Input } from 'semantic-ui-react'

import './styles.css';

function Contact() {
    return (
        <div id="contactRoot" className="page-container contact-root">
            <p className="primary-text">Contact</p>
            <p className="secondary-text">Let&apos;s Stay in Touch</p>
            <Form style={{ marginTop: '40px' }}>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='First name' placeholder='First name' />
                    <Form.Input fluid label='Last name' placeholder='Last name' />
                </Form.Group>
                <Form.Field
                    control={Input}
                    label='Email'
                    placeholder='jdoe@email.com'
                />
                <Form.TextArea label='Message' placeholder='Tell me more about you...' />
                <Form.Button color='#9ABD97'>Send</Form.Button>
            </Form>
        </div>
    );
}

export default Contact;