import React from 'react';

import { Image } from 'semantic-ui-react';

import './styles.css';

import SkillBar from './SkillBar';
import Timeline from './Timeline';

import ProfilePhoto from '../../assets/hiking-photo.png';

function About() {
    return (
        <div id="aboutRoot" className="page-container" style={{ padding: '20px 0 0 0' }}>
            <div className="left-content">
                <p className="primary-text">About Me</p>
                <p className="secondary-text">My Journey</p>
                <Image src={ProfilePhoto} size="small" />
                <p className="description-text">
                    I graduated with a Master&apos;s degree in 2020 and a dream to find new ways to use technology to solve real world problems.
                    For the past two years, I've been working as a fullstack developer on the Data Lake Platform team, taking charge of the design
                    and implementation of various features on an internal batch processing tool. <a>Let&apos;s get connected.</a>
                </p>
                <div className="skillbar-container">
                    <div>
                        <SkillBar skillname="Java" progress={70} />
                        <SkillBar skillname="Python" progress={70} />
                        <SkillBar skillname="JavaScript" progress={70} />
                        <SkillBar skillname="SQL" progress={70} />
                    </div>
                    <div>
                        <SkillBar skillname="React" progress={70} />
                        <SkillBar skillname="Android" progress={70} />
                        <SkillBar skillname="SpringBoot" progress={70} />
                    </div>
                </div>
            </div>
            <div className="right-content">
                <Timeline />
            </div>
        </div>
    );
}

export default About;