import React, { createRef } from 'react';

import { Sticky } from 'semantic-ui-react';

import NavigationBar from './components/navbar/NavigationBar';
import Home from './components/home/Home';
import About from './components/about/About';
import Projects from './components/projects/Projects';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

import './index.css';

function App() {
  const contextRef = createRef();

  return (
    <div ref={contextRef}>
      <Home />
      <Sticky context={contextRef} pushing>
        <NavigationBar />
      </Sticky>
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
